.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4;

  .not-found-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }

  .home-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      background-color: #353b42;
    }
  }
}

@media (max-width: 768px) {
  .not-found-container {
    .not-found-image {
      max-width: 80%;
    }
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .not-found-container {
    .not-found-image {
      max-width: 70%;
    }
    h2 {
      font-size: 1.3rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
}
