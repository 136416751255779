@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import "../genral.scss";

.navbar {
  box-shadow: none;
  background-color: #ffffff;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 0;
  @media screen and (min-width: 1200px) {
    max-width: 1201px !important;
  }
}

.linkStyle {
  font-size: 20px;
  line-height: 1.6;
  margin: 0 15px;
  padding: 10px 15px;
  font-family: "Nunito", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #333333;
}

.routeLink {
  text-decoration: none;
  color: #555555;
  padding: 7px 15px;
}

.service-dropdown.dropdown {
  display: flex;
}

.logo-text {
  color: #fff;
  padding-left: 10px;
  font-size: 1.5rem;
  width: 100%;
  white-space: normal;
  //   font-size: 2.2rem;
  //   font-weight: bold;
  // color: rgba(225, 92, 15, 0.927);
  //   animation: zoomInOut 3s ease-in-out infinite;
  //
  //   @keyframes zoomInOut {
  //     0%,
  //     100% {
  //       transform: scale(1);
  //     }
  //     50% {
  //       transform: scale(1.1);
  //     }
  //   }
}
.navbar-toggler-icon {
  background-color: white;
  padding: 20px;
  // width: 5px;
  border-radius: 5px;
}

@media screen and (max-width: 992px) {
  .navbar-toggler {
    background-color: #ffffff;
  }
}
nav.navbar.navbar-expand-lg.navbar-light {
  padding: 10px 5px !important;
}

.upper-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 10px;
}
.upper-heading {
  font-size: 20px;
  font-family: "Montserrat";
}
.upper-header > div {
  margin-bottom: 10px;
}
.upper-header > div > a {
  color: #fff;
  text-decoration: none;
}

.upper-header span {
  margin-right: 5px;
}

@media (max-width: 769px) {
  .upper-header {
    display: flex;
    flex-direction: column;
  }
  .heading {
    font-size: 15px;
  }
  .email,
  .phone {
    display: none;
  }
  .upper-header > div {
    margin-bottom: 0;
  }
}
