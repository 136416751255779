.explore-slider-container {
  width: 100%;
  margin: 0 auto;
}

.explore-slider-item {
  width: 100%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
}
