.gallery {
  position: relative;
  .cover-img {
    width: 100%;
    height: 500px;
    // height: auto;
    object-fit: cover;
    // position: absolute;
    z-index: -1;
  }

  @media (min-width: 425px) {
    .cover-img {
      width: 100%;
    }
  }

  .discription {
    align-content: justify;
  }
}
