@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,500&display=swap");

.heading {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  font-style: normal;

  text-align: center;
  text-transform: uppercase;
}

.heading2 {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
}

.discoverMore {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 16px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px;
  padding: 16px 32px;
  border: 1px solid;
  width: 280px;
  border-radius: 5px;
  margin-top: 70px;
}
.your-component.fade-in {
  opacity: 1;
  transform: translateY(0);
  animation: slide-up 3s forwards;
}
