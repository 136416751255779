.text-reset {
  transition: 0.5s;
}
.text-reset:hover {
  background-color: #ccc;
  border-radius: 10px;
}
.your-component.fade-in {
  opacity: 1;
  transform: translateY(0);
  animation: slide-up 1.5s forwards;
}
@media (max-width: 768px) {
  .footer-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 6rem !important;
  }
}
