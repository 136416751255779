.dropdown-message {
  top: 10rem;
  left: 50%;
  opacity: 1;
  z-index: 999;
  font-size: 5rem;
  position: fixed;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  transform: translateX(-50%);
  animation: fadeInAndZoom 6s ease;
}

@keyframes fadeInAndZoom {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }
  50% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }
}
