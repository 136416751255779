@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,500&display=swap");

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.culture,
.cultureDesc,
.image {
  animation-duration: 2s;
  animation-fill-mode: both;
  // opacity: 0;
}

.culture {
  animation-name: slideRight;
}

.cultureDesc {
  animation-name: slideLeft;
}

.image {
  animation-name: slideLeft;
}

@media screen and (max-width: 1024px) {
  .culture,
  .cultureDesc,
  .image {
    animation-name: none;
  }

  .culture,
  .cultureDesc,
  .image {
    text-align: center;
  }

  .customCol {
    align-items: center;
  }

  .cultureDesc {
    width: 100%;
    margin-top: 10px;
  }

  .image {
    // width: 100%;
    // margin-top: 20px;
    display: block;
  }
}

@media screen and (max-width: 724px) {
  .culture,
  .cultureDesc,
  .image {
    text-align: center;
  }

  .customCol {
    align-items: center;
  }

  .cultureDesc {
    width: 100%;
    margin-top: 10px;
  }

  .image {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 425px) {
  .culture,
  .cultureDesc,
  .image {
    text-align: center;
  }

  .customCol {
    align-items: center;
  }

  .cultureDesc {
    width: 100%;
    margin-top: 10px;
  }

  .image {
    // display: none;
  }
}

.culture {
  font-family: "Montserrat";
  font-size: 22px;
  line-height: 20px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  text-decoration: none solid rgb(10, 5, 0);
  font-weight: 600;
  color: #000;
}

.customCol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.cultureDesc {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 23.4px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px;
  padding: 0px;
  text-decoration: none solid rgb(10, 5, 0);
  width: 390px;
}

.image {
  width: 350px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 769px) {
  .cultureDesc {
    // padding-left: 60px;
    // // padding-right: 50px;
    // text-align: justify;
  }
}
