@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import "../genral.scss";

/* Story Container Styles */
.storyContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f5f5f5;
  margin-top: 32px;
}

/* Story Styles */
.story {
  font-size: 16px;
  letter-spacing: 1.5px;
  line-height: 35px;
  display: flex;
  text-align: justify;
  color: #444;
  font-family: "Nunito", Arial, Helvetica, sans-serif;
  max-width: 500px; /* Set a max-width to prevent overflow on smaller screens */
  width: 100%; /* Make it fluid */
}

/* Story Image Styles */
.storyImag {
  width: 600px;
  border-radius: 30px;
}

/* Media Queries */
@media (max-width: 1024px) {
  .storyImag {
    width: 500px;
  }
  .story {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .storyContainer {
    flex-direction: column;
    align-items: center;
  }
  .storyImag {
    display: none;
  }
  .story {
    width: 100%;
    padding: 20px;
    max-width: 600px;
  }
}

@media (max-width: 425px) {
  .story {
    width: 100%;
  }
  .storyImag {
    display: none;
  }
}
