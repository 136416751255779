.upperVidContainer {
  background-color: #f5f5f5;
  padding-bottom: 60px;
}

.videoContainer {
  display: flex;
  justify-content: center;
}

.tumbnailList {
  margin-left: 35px;
  margin-top: 90px;
}

.thumnail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vidName {
  margin-left: 10px;
  font-size: 14px;
  font-family: cursive;
}

.videoDetails {
  display: flex;
  background-color: #fff;
  width: 340px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  cursor: grab;
  transition: 1s;
}
.videoDetails:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
