@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  font-family: "Roboto", sans-serif;
  color: #333;
}

.send-query-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

h2 {
  margin-bottom: 10px;
  color: #333;
}

form {
  width: 100%;

  .form-group {
    margin-bottom: 15px;

    label {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .btn {
    width: 100%;
    padding: 10px;
    margin: 20px 0 20px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: #000;
    border: none;
    outline: none;
  }
  .btn:hover {
    background-color: #000;
  }

  .form-control {
    margin-bottom: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1.5px solid #000;
  }
}

.mt-5 {
  margin-top: 2rem !important;
}

.modal-title {
  font-weight: bold;
}

.modal-body {
  font-size: 1em;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 768px) {
  .send-query-container {
    width: 100%;
  }
  .questionImage {
    width: 100% !important;
  }
}

.form-check-input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 0 !important;
  border-radius: 5px !important;
  border: 1.5px solid #000 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.form-check-label {
  margin-bottom: 10px;
}

fieldset > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
