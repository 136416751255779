@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,500&display=swap");
.aboutUs {
  display: flex;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
  background-color: rgba(10, 5, 0, 0.33);
  flex-direction: column;
}

.about {
  padding-top: 100px;
  color: #fff;
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 600;
}
.discription {
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  width: 750px;
  line-height: 24.75px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  padding: 0px;
}

.imgContainer1 {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  .image {
    flex: 0 0 auto; // Prevent flex items from stretching
    margin-right: 10px; // Space between images
  }
}

.team {
  width: 288px;
  margin: 20px;
  border-radius: 7px;
  filter: grayscale(100%);
}
