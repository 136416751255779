$whatsapp-color: #25d366;
$facebook-color: #3b5998;
$instagram-color: #e1306c;
$linkedin-color: #0e76a8;

.sticky-buttons {
  position: fixed;
  top: 50%;
  right: 0.6%;
  transform: translateY(-50%);
  z-index: 1000;
  list-style: none;

  li {
    margin: 0.5rem 0;

    a {
      position: relative;
      display: flex;
      align-items: center;
      color: #000;
      padding: 0.5rem;
      border-radius: 0.25rem;
      text-decoration: none;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

      &.whatsapp {
        background-color: $whatsapp-color;
        .icon,
        .label {
          color: #fff;
        }
        .label {
          background-color: $whatsapp-color;
        }
      }

      &.facebook {
        background-color: $facebook-color;
        .icon,
        .label {
          color: #fff;
        }
        .label {
          background-color: $facebook-color;
        }
      }

      &.instagram {
        background-color: $instagram-color;
        .icon,
        .label {
          color: #fff;
        }
        .label {
          background-color: $instagram-color;
        }
      }

      &.linkedin {
        background-color: $linkedin-color;
        .icon,
        .label {
          color: #fff;
        }
        .label {
          background-color: $linkedin-color;
        }
      }

      .label {
        position: absolute;
        right: 100%;
        margin-right: 10px;
        white-space: nowrap;
        padding: 5px;
        border-radius: 5px;
        opacity: 0;
        transform: translateX(10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }

      &:hover .label {
        display: block;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}
@media (max-width: 1024px) {
}
