.your-component {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.your-component.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  transition: 1s;
}

.image-container img {
  width: 100%;
  display: block;
  border-radius: 10px;
  margin: 8px;
}

.overlay-text {
  top: 50%;
  left: 52%;
  width: 90%;
  width: 100%;
  height: 100%;
  padding: 10px;
  height: 290px;
  display: flex;
  color: white;
  font-size: 12px;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
}

.overLayImage {
  position: absolute;
  top: 2.5%;
  left: 2%;
  right: 3%;
  display: block;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
}

.overLayImage:hover {
  opacity: 0;
}

.overlay-text1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  text-align: center;
  line-height: 27px;
  padding: 10px;
  border-radius: 5px;
}

/* Button Styles */
.btn {
  margin-top: 0px;
}

.explore-more-button {
  padding: 10px 15px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #434349;
  }
}
