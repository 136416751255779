/* Default styles for larger screens */
.explore-more {
  display: flex;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
  background-color: rgba(10, 5, 0, 0.33);
  flex-direction: column;
}

.explore-slider-container {
  width: 100%;
}

.explore {
  padding-top: 100px;
  color: #fff;
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 600;
}

.discription {
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  width: 750px;
  line-height: 24.75px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  padding: 0px;
}

@media screen and (max-width: 768px) {
  .explore {
    font-size: 24px;
  }

  .discription {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .explore {
    font-size: 20px;
    padding-top: 50px;
  }

  .discription {
    width: 100%;
    font-size: 0.9rem;
  }
}
