.slick-slide {
  img {
    width: 100%;
    height: 35rem;
    object-fit: cover;
  }
}

@media screen and (max-width: 1025px) {
  .slick-slide {
    height: 80vh;
  }
}

@media screen and (max-width: 769px) {
  .slick-slide {
    height: 70vh;
  }
}

@media screen and (max-width: 600px) {
  .slick-slide {
    height: 60vh;
  }
}

@media screen and (max-width: 425px) {
  .slick-slide {
    height: 50vh;
  }
}

// style.scss

.slider-item {
  position: relative;

  img {
    // height: 35rem;
  }
}

.text-container {
  position: absolute;
  top: 50%;
  // left: 5%;
  transform: translate(50%, -50%);
  text-align: left;
  color: white;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
  }

  @media only screen and (max-width: 576px) {
    .title {
      font-size: 2rem;
      align-content: justify;
    }
    .quote {
      font-size: 1rem;
      align-items: center;
    }
  }
}

.title {
  color: #fff;
  font-size: 5rem;
  margin-bottom: 10px;
}

.quote {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

button.btn.btn-secondary {
  border: none;
  background-color: #000;
}
.services-btn {
  outline: black;
  color: #fff;
  text-decoration: none;
  // color: #000;
  // outline: none;
  // text-decoration: none;
  // background-color: #fff;
}
